import AboutTitle from '../Components/AboutTitle';
import AboutPerson from '../Components/AboutPerson';

const AboutPage = () => {
    return (
        <>
            <AboutTitle />

            <AboutPerson />

        </>
    )
};

export default AboutPage;