import Dashboard from '../Components/Dashboard';

const MapPage = () => {
    return (
        <>
            <Dashboard />
        </>
    )
};

export default MapPage;