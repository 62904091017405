import TermsAndConditions from '../Components/TermsAndConditions';

const TermsAndConditionsPage = () => {
    return (
        <>
            <TermsAndConditions />
        </>
    )
};

export default TermsAndConditionsPage;