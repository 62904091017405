import PrivacyPolicy from '../Components/PrivacyPolicy';

const PrivacyPolicyPage = () => {
    return (
        <>
            <PrivacyPolicy />
        </>
    )
};

export default PrivacyPolicyPage;