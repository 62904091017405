import NotFoundTitle from "../Components/NotFoundTitle";

const NotFoundPage = () => {
    return (
        <>
            <NotFoundTitle />
        </>
    )
};

export default NotFoundPage;